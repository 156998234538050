.top-container-full-width {
  background: linear-gradient(90deg, #396884 0%, #4884a8 50%);
  display: flex; }
  .top-container-full-width .top-container {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0px 40px; }

.login-container {
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px; }
  .login-container img {
    width: 200px;
    margin-bottom: 20px; }
  .login-container .login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .login-container .login-form input {
      width: 100%;
      margin: 4px 0px;
      padding: 0.5rem 1rem; }
    .login-container .login-form input[type='submit'] {
      margin: 10px 0px;
      background-color: #396884;
      color: #fff;
      border: #315a72; }

.info-container {
  width: 50%; }
  .info-container h1,
  .info-container p {
    color: white; }
  .info-container h1 {
    font-size: 32px;
    font-weight: bold; }
  .info-container p {
    font-size: 20px;
    margin: 20px 0px; }
  .info-container a {
    font-size: 20px;
    color: white;
    text-decoration: underline; }

@media (max-width: 800px) {
  .info-container {
    display: none; } }
